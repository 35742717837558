import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Api from "../../../helpers/Api";
import Input from "../../../components/form/Input";
import Button from "../../../components/form/Button";
import AppLayout from '../../../components/layout/AppLayout';
import AppTheme from '../../../themes/AppTheme';
import styled from "styled-components";
import SubHeader from '../../../components/subheader/SubHeader';
import LoadingPage from "../../loading/LoadingPage";

export const Container = styled.div`
  width: 100%;
  padding: 0 14px;

  > form {
    background: ${AppTheme.header.colors.whiteColor};
    padding: 10px 20px;
    width: 100%;
    float: left;
  }
`;

export const StepsCol = styled.div`
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Col = styled.div`
  height: 60px;
  background: ${AppTheme.header.colors.lightBlueColor};
  display: flex;
  align-items: center;
  padding: 0 20px 0 50px;
  position: relative;

  > span {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: ${AppTheme.header.colors.blueColor};
  }

  &.active {
    background: ${AppTheme.header.colors.blueColor};
    padding: 0 20px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: -30px; /* Adjust the distance of the arrow from the right edge */
      width: 0;
      height: 0px;
      border-top: 30px solid transparent; /* Adjust size of arrow */
      border-bottom: 30px solid transparent; /* Adjust size of arrow */
      border-left: 30px solid ${AppTheme.header.colors.blueColor}; /* Adjust color and size of arrow */
      transform: translateY(-50%);
      z-index: 999;
    }
  }

  &.active {
    > span {
      color: ${AppTheme.header.colors.whiteColor};
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -30px; /* Adjust the distance of the arrow from the right edge */
    width: 0;
    height: 0px;
    border-top: 30px solid transparent; /* Adjust size of arrow */
    border-bottom: 30px solid transparent; /* Adjust size of arrow */
    border-left: 30px solid ${AppTheme.header.colors.lightBlueColor}; /* Adjust color and size of arrow */
    transform: translateY(-50%);
    z-index: 999;
  }

`;

export const FormGroup = styled.div`
  padding: 20px 0px;
  border-bottom: 1px solid ${AppTheme.header.colors.tableBorder};

  > div {
    margin-bottom: 0px;

    > span {
      width: calc(100% - 224px);
      margin: 8px 0 0 auto;
    }
  }

  > .select-col {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    > div {
      margin-bottom: 0px;

      > select {
        width: 100%;
        border-radius: 2px;
        border: 1px solid ${AppTheme.header.colors.inputBorder};
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        height: 50px;
      }
    }

    > label {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: ${AppTheme.header.colors.blackColor};
      margin-bottom: 0px;
      text-align: left;
      width: 280px;
      padding-right: 20px;

      > span {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: ${AppTheme.header.colors.errorTextColor};
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;

  > a {
    text-decoration: none;
    border-radius: 6px;
    border: 1px solid ${AppTheme.header.colors.inputBorder};
    background: ${AppTheme.header.colors.whiteColor};
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    margin-right: 15px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    color: ${AppTheme.header.colors.blackColor};
  }

  > div {
    margin-top: 0px;

    > button {
      margin-bottom: 0px;
      height: 40px;
      padding: 0 15px;
      border-radius: 6px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }
  }

`;

const CreateTemplate = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [template, setTemplate] = useState({
        name: '',
        departmentId: '',
        data: {about:''}
    });
    const [departments, setDepartments] = useState([]);
    const [schools, setSchools] = useState([]);
    const [loading, setLoading] = useState(false);
    const [initialising, setInitialising] = useState(true);
    const [error, setError] = useState('');
    const [errors, setErrors] = useState({})
    const [totalAwarded, setTotalAwarded] = useState(0);

    useEffect(() => {
        Api.listDepartments().then(departments => {
            setDepartments(departments);
        }).catch(err => {
            console.log(err);
        });
        Api.listSchools(true).then(schools => {
            setSchools(schools);
        });
    }, []);

    useEffect(() => {
        if (!id) {
            setInitialising(false);
            return;
        }

        setLoading(true);
        Api.getTemplate(id).then(templateData => {
            setTemplate({
                name: templateData.name,
                data: templateData.data,
                departmentId: templateData.department.id,
                schoolId: templateData.school?.id,
            });
            setTotalAwarded(templateData.totalAwarded);
            setLoading(false);
        }).catch(err => {
            setError('Failed to load template. Please try again.');
            setLoading(false);
        }).finally(() => setInitialising(false));

    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const errors = {};
            // Validate template name
            if (!template.name.trim()) {
                errors.name = "Template name cannot be empty.";
            }

            // Validate department ID
            if (!template.departmentId) {
                errors.departmentId = "Please select a department.";
            }

            if (!template.data.issuer) {
                errors.issuer = "Issuing Institution cannot be empty.";
            }

            // Check if there are any validation errors
            if (Object.keys(errors).length > 0) {
                setErrors(errors);
                setLoading(false);
                return;
            }
            let templateResult;
            if (id) {
                templateResult = await Api.updateTemplate(id, template.name, template.departmentId, template.schoolId, template.data);
            } else {
                templateResult = await Api.createTemplate(template.name, template.departmentId, template.schoolId, template.data, {
                    "width": 595,
                    "height": 842,
                    "fonts": [
                        {
                            "fontFamily": "Arial",
                            "url": "",
                            "styles": [
                                {
                                    "src": "url(https://verify.chitkara.edu.in/assets/fonts/arial/Arial.ttf)",
                                    "fontStyle": "normal",
                                    "fontWeight": "normal"
                                },
                                {
                                    "src": "url(https://verify.chitkara.edu.in/assets/fonts/arial/Arial_Bold.ttf))",
                                    "fontStyle": "normal",
                                    "fontWeight": "bold"
                                },
                                {
                                    "src": "url(https://verify.chitkara.edu.in/assets/fonts/arial/Arial_Italic.ttf)",
                                    "fontStyle": "italic",
                                    "fontWeight": "normal"
                                },
                                {
                                    "src": "url(https://verify.chitkara.edu.in/assets/fonts/arial/Arial_Bold_Italic.ttf)",
                                    "fontStyle": "italic",
                                    "fontWeight": "bold"
                                }
                            ]
                        },
                        {
                            "fontFamily": "Times New Roman",
                            "url": "",
                            "styles": [
                                {
                                    "src": "url(https://verify.chitkara.edu.in/assets/fonts/tnr/Times_New_Roman.ttf)",
                                    "fontStyle": "normal",
                                    "fontWeight": "normal"
                                },
                                {
                                    "src": "url(https://verify.chitkara.edu.in/assets/fonts/tnr/Times_New_Roman_Bold.ttf)",
                                    "fontStyle": "normal",
                                    "fontWeight": "bold"
                                },
                                {
                                    "src": "url(https://verify.chitkara.edu.in/assets/fonts/tnr/Times_New_Roman_Italic.ttf)",
                                    "fontStyle": "italic",
                                    "fontWeight": "normal"
                                },
                                {
                                    "src": "url(https://verify.chitkara.edu.in/assets/fonts/tnr/Times_New_Roman_Bold Italic.ttf)",
                                    "fontStyle": "italic",
                                    "fontWeight": "bold"
                                }
                            ]
                        }
                    ],
                    "pages": [
                        {
                            "id": "8HqWmTEJkJ",
                            "children": [],
                            "width": "auto",
                            "height": "auto",
                            "background": "white",
                            "bleed": 0,
                            "duration": 5000
                        }
                    ],
                    "unit": "px",
                    "dpi": 72
                });
            }
            if (templateResult.totalAwarded === 0) {
                navigate(`/templates/${templateResult.id}/design`);
            } else {
                navigate(`/templates`);
            }
        } catch (error) {
            setError('Failed to process template. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    const handleAboutChange = (e) => {
        const aboutText = e.target.value;
        const wordCount = aboutText.trim().split(/\s+/).length;

        if (wordCount > 50) {
            setError('The description must be less than 50 words.');
        } else {
            setError('');
            setTemplate({
                ...template,
                data: { ...template.data, about: aboutText }
            });
        }
    };

    const isReadOnly = totalAwarded > 0;

    if (initialising) {
        return <LoadingPage/>
    }

    return (
        <AppLayout>
            <SubHeader
                heading={'Credential Template Editor'}
                description={'Create Professional Credentials with Ease'}
            />
            <Container>
                <StepsCol>
                    <Col className='active'>
                        <span>{id ? 'Step 1: Update Program Details' : 'Step 1: Enter Program Details'}</span>
                    </Col>
                    <Col>
                        <span>Step 2: Design Your Template</span>
                    </Col>
                    <Col>
                        <span>Step 3: Configure verification</span>
                    </Col>
                </StepsCol>
                <form onSubmit={handleSubmit}>
                    <FormGroup>
                        <div className='select-col'>
                            <label>Select Department <span>*</span></label>
                            <Input type={'select'} value={template.departmentId} name={'departmentId'}
                                   options={departments.map(
                                       d => ({value: d.id, label: d.name})
                                   )}
                                   readOnly={isReadOnly}
                                   onChange={(e) => setTemplate({...template, departmentId: e.target.value})}
                                   defaultText='Select Department'
                                   error={errors.departmentId}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <div className='select-col'>
                            <label>Select School </label>
                            <Input type={'select'} value={template.schoolId} name={'schoolId'}
                                   options={schools.map(
                                       d => ({value: d.id, label: d.name})
                                   )}
                                   readOnly={isReadOnly}
                                   onChange={(e) => setTemplate({...template, schoolId: e.target.value})}
                                   defaultText='Select school'
                                   error={errors.schoolId}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Input
                            label="Template Name"
                            astricSign='*'
                            type="horizontalinput"
                            value={template.name}
                            onChange={(e) => setTemplate({...template, name: e.target.value})}
                            name="name"
                            readOnly={isReadOnly}
                            placeholder={'Enter Template name'}
                            error={errors.name}
                        />

                    </FormGroup>


                    <FormGroup>
                        <Input
                            label='Program'
                            type='horizontalinput'
                            value={template.data.program}
                            onChange={(e) => setTemplate({
                                ...template,
                                data: {...template.data, program: e.target.value}
                            })}
                            readOnly={isReadOnly}
                            name="value1"
                            placeholder={'Enter Program name'}
                        />
                    </FormGroup>

                    <FormGroup>
                        <div className='select-col'>
                            <label>Select Year</label>
                            <Input
                                type="select"
                                options={[
                                    {label: '2020', value: '2020'},
                                    {label: '2021', value: '2021'},
                                    {label: '2022', value: '2022'},
                                    {label: '2023', value: '2023'},
                                    {label: '2024', value: '2024'}
                                ]}
                                value={template.data.year}
                                onChange={(e) => setTemplate({
                                    ...template,
                                    data: {...template.data, year: e.target.value}
                                })}
                                readOnly={isReadOnly}
                                name="year"
                                defaultText='Select Year'
                                error={errors.year}
                            />
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <Input
                            label='Issuing Institution'
                            astricSign='*'
                            type='horizontalinput'
                            value={template.data.issuer}
                            onChange={(e) => setTemplate({
                                ...template,
                                data: {...template.data, issuer: e.target.value}
                            })}
                            readOnly={isReadOnly}
                            name="value1"
                            error={errors.issuer}
                            placeholder={'Example: National Institute of Technology'}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            label='About, A write-up about the event, programme, or credential (less than 50 words).'
                            type='textarea'
                            astricSign='*'
                            value={template.data.about}
                            onChange={handleAboutChange}
                            name="value2"
                            placeholder={'Example: In Computer Science degree, rigorous coursework covers theory and practical applications, emphasizing algorithms and software development. '}
                            error={error}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            label='Acquired Skills (you can write multiple skills each separated by a comma ",")'
                            type='horizontalinput'
                            value={template.data.skills}
                            onChange={(e) => setTemplate({
                                ...template,
                                data: {...template.data, skills: e.target.value}
                            })}
                            name="value3"
                            placeholder={'Example: Agile, Scrum, DevOps'}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            label='Credential Signatorys Name'
                            type='horizontalinput'
                            value={template.data.signatories}
                            multi={true}
                            onChange={(e) => setTemplate({
                                ...template,
                                data: {...template.data, signatories: e.target.value}
                            })}
                            readOnly={isReadOnly}
                            name="value4"
                            placeholder={'Example: Name of Conference Program Committee Head, Name of Conference Chair'}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            label='Earning Criteria'
                            type='textarea'
                            value={template.data.earningCriteria}
                            onChange={(e) => setTemplate({
                                ...template,
                                data: {...template.data, earningCriteria: e.target.value}
                            })}
                            name="value5"
                            placeholder={'Example: Completing credits, maintaining GPA, fulfilling course requirements, gaining practical experience, passing exams, attending classes, and upholding ethical standards.'}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Input
                            readOnly={isReadOnly}
                            label='Days valid for'
                            type='horizontalinput'
                            value={template.data.expiryDays}
                            onChange={(e) => setTemplate({
                                ...template,
                                data: {...template.data, expiryDays: e.target.value}
                            })}
                            name="value6"
                        />
                    </FormGroup>
                    <ButtonContainer>
                        <Link to={`/templates`}>
                            Cancel
                        </Link>
                        <Button disabled={loading} type={'submit'} loading={loading}
                                label={id ? "Save and Next" : "Create Template"}/>
                    </ButtonContainer>

                    {error && <div style={{color: 'red'}}>{error}</div>}
                </form>
            </Container>

        </AppLayout>
    );
};

export default CreateTemplate;
