import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Api from "../../helpers/Api"; // Adjust the import path as necessary
import AppLayout from '../../components/layout/AppLayout';
import SubHeader from '../../components/subheader/SubHeader';
import CommonTable from '../components/table/CommonTable';
import styled from "styled-components";
import LoadingPage from "../loading/LoadingPage";
import AppTheme from '../../themes/AppTheme';
import NotFound from '../components/Notfound/NotFound';
import NotFoundIcon from '../../images/not-found.svg';

export const Container = styled.div`
  border-radius: 5px;
  border: 1px solid ${AppTheme.header.colors.tableBorder};
  background: ${AppTheme.header.colors.whiteColor};
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  float: left;
  width: 100%;
  padding: 0;
`;

const ListDepartments = () => {
    const [departments, setDepartments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const departmentColumns = [
        {key: 'name', label: 'Name'},
        {key: 'createdAt', label: 'Created At', type: 'datetime'},
        {key: 'updatedAt', label: 'Updated At', type: 'datetime'},
    ];

    useEffect(() => {
        fetchDepartments();
    }, []);

    const fetchDepartments = async () => {
        setLoading(true);
        try {
            const data = await Api.listDepartments();
            setDepartments(data);
            setError('');
        } catch (err) {
            setError('Failed to fetch departments. Please try again later.');
        } finally {
            setLoading(false);
        }
    };
    const handleDelete = async (id) => {
        await Api.deleteDepartment(id);
        fetchDepartments();
    };

    const handleEdit = (id) => {
        navigate(`/departments/${id}`);
    };

    const handleViewUsers = (id) => {
        navigate(`/departments/${id}/users`);
    };

    if (loading) return <LoadingPage/>
    if (error) return <div>Error: {error}</div>;

    return (
        <AppLayout>
            <Container>
                <SubHeader
                    heading='Departments'
                    description={'View Credential Issuance Across Departments'}
                    href={'/departments/create'}
                    label={'Add New Department'}
                />

                {/* Table component */}
                {departments.length > 0 ? (
                    <CommonTable
                        columns={departmentColumns}
                        data={departments}
                        getActionsConfig={(item) => {
                            return {
                                edit: true,
                                delete: item.issuedCertificates > 0 ? 'disabled' : true,
                                view: false
                            }
                        }}
                        onDelete={handleDelete}
                        onEdit={handleEdit}
                        onView={handleViewUsers}
                    />
                ) : (
                    <NotFound
                        icon={NotFoundIcon}
                        message={'Looks like you have no department  yet'}
                    />
                )}
            </Container>
        </AppLayout>
    );
};

export default ListDepartments;
